 <!-- 校务管理 > 宿舍管理 > 宿舍分配(学生) -->
<template>
    <div style="margin-top: -10px;">
        <div v-if="!errorShow" class="right-panel">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-main">
                <expand-filter
                    borderRadius="0px 4px 4px 4px"
                    :closeWidth="1190"
                    :formData="formData"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    @changeSel="changeSel"
                >
                <div slot="dropdown" style="margin-left:auto;white-space:nowrap;">
                    <span style="display:inline-block;width:10px;"></span>
                    <el-button type="danger" @click="multiDelete"  v-hasPermi="['dormitoryDistribution:cancel']" style="margin-left: 0px;">取消分配</el-button>
                    <el-button
                        type="primary"
                        @click="handleImport"
                        class="download-btn"
                        v-hasPermi="downLoadTempBtn"
                    >
                        下载模板
                    </el-button>
                    <el-upload
                        ref="upload"
                        :action="action"
                        :headers="headers"
                        accept=".xlsx,.xls"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                        :with-credentials="true"
                        :show-file-list="false"
                        :data="{schoolId: schoolId}"
                        class="upload-demo"
                    >
                        <el-button type="enquiry" v-hasPermi="importBtn">
                            <span>导入</span>
                        </el-button>
                    </el-upload>
                    <el-button type="enquiry" @click="handleExport" v-hasPermi="exportBtn">
                        <span>导出</span>
                    </el-button>
                </div>
            </expand-filter>

                <table-data
                    ref="table"
                    v-loading="loadingTable"
                    :config="table_config"
                    :tableData="table_data"
                    @handleSelectionChange="handleTableSelectionChange"
                >
                    <template v-slot:operation="slotData">
                        <xk-button
                            type="primary"
                            size="mini"
                            @click="handleDistribution(slotData.data)"
                            v-hasPermi="['dormitoryDistribution:adjustment']"
                        >调整</xk-button>
                        <xk-button
                            v-if="slotData.data.allocationStatus === '1'"
                            type="danger"
                            size="mini"
                            @click="handleTableDelete(slotData.data)"
                            v-hasPermi="['dormitoryDistribution:cancel']"
                        >取消分配</xk-button>
                    </template>
                </table-data>
                <pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                />
                <!--分配-->
                <dialog-wrapper :dialogObj="dialogObj" @handleClose="handleClose">
                    <dialog-form-data
                        v-loading="dialogLoading"
                        :dormitory-list="dormitoryList"
                        :floor-list="floorList"
                        :form="ruleForm"
                        :list="dialogTableList"
                        @dormitoryChange="dormitoryChange"
                        @getList="getDialogTableList"
                        @submitForms="handleSubmit"
                        @handleClose="handleClose"
                    />
                </dialog-wrapper>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
    // 工具函数
    debounce
} from 'common-local';
import { mapState } from "vuex";
import TableData from "../Sub/TableData"
import { downloadFile, formatTreeData } from '@/libs/utils.js'
import { SchoolDormitory } from "@/models/SchoolDormitory";
import ExpandFilter from "../Sub/ExpandFilter";
import OperationBtnView from "../SchoolDormitory/OperationBtnView";
import dialogFormData from "../SchoolDormitory/dormitoryDistribution/dialogFormData";
import onResize from "@/mixins/onResize"
import {getToken} from "@/libs/auth";
export default {
    name:"DormitoryDistributionStudent",
    mixins: [onResize],
    components: {
        OperationBtnView,
        TableData,
        DialogWrapper,
        // 功能组件
        Error,
        Loading,
        Pagination,
        ExpandFilter,
        dialogFormData,
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
        }),
        action() {
            return '/api/school/schoolDormitoryDistribution/upload'
        },
        // 下载模板
        downLoadTempBtn(){
            return ['dormitoryDistribution:downLoadTemp']
        },
          //导入
          importBtn(){
            return ['dormitoryDistribution:import']
        },
        //导出
        exportBtn(){
            return ['dormitoryDistribution:export']
        },
        headers() {
            return {
                Authorization: getToken(),
            }
        },
    },
    data() {
        return {
            listQuery: {
                schoolId: '',
                organIdList: [],
                studentName: '',
                onlyCode: '',
                studentType:"",
                allocationStatus: '',
                dormitoryId: '',
                floorId: '',
                roomName: '',
                pageNum: 1,
                pageRow: 20
            },
            subjects: [],
            total: 0,
            // 头部筛选
            formData: {
                data: [
                    {
                        type: 'cascader',
                        label: '',
                        value: [],
                        placeholder: '班级',
                        key: 'organIdList',
                        cascaderProps: {
                            label: 'name',
                            value: 'id',
                            emitPath: false,
                            multiple: true
                        },
                        list: []
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '姓名',
                        key: 'studentName',
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '唯一号',
                        key: 'onlyCode',
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '分配状态',
                        key: 'allocationStatus',
                        list: [
                            { label: '待分配', value: '0' },
                            { label: '已分配', value: '1' },
                        ]
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '宿舍楼',
                        key: 'dormitoryId',
                        list: []
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '楼层',
                        key: 'floorId',
                        list: []
                    },
                    {
                        type: 'input',
                        label: '',
                        value: '',
                        placeholder: '宿舍',
                        key: 'roomName'
                    },
                    {
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '学生类型',
                        key: 'studentType',
                        list: [
                            { label: '住宿', value: '0' },
                            { label: '走读', value: '1' },
                            { label: '半读', value: '2' },
                        ]
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: '查询',
                        fn: 'primary',
                        auth:['dormitoryDistribution:list']
                    },
                    {
                        type: "enquiry",
                        text: '重置',
                        fn: 'reset',
                        auth:['dormitoryDistribution:list']
                    }
                ],
                btnFormType: true
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "班级",
                        prop: "className",
                        type: "popoverText",
						labelWidth:'180px',
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "姓名",
                        prop: "studentName",
                        labelWidth:'120px',
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "学生类型",
                        prop: "studentType",
                        type: "function",
                        align: 'center',
                        callBack(row) {
                            return {
                                0: '住宿',
                                1: '走读',
                                2: '半读'
                            } [row.studentType]
                        },
                    },
                    {
                        label: "唯一号",
                        prop: "onlyCode",
                        align: 'center'
                    },
                    {
                        label: "性别",
                        prop: "sex",
                        type: "function",
                        callBack(row) {
                            return {
                                1: '男',
                                2: '女'
                            } [+row.sex]
                        },
						labelWidth:'100px',
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "分配状态",
                        prop: "allocationStatus",
                        type: "function",
                        align: 'center',
                        callBack(row) {
                            return {
                                0: '待分配',
                                1: '已分配'
                            } [+row.allocationStatus]
                        },
                    },
                    {
                        label: "宿舍楼",
                        prop: "dormitoryName",
                        align: 'center'
                    },
                    {
                        label: "楼层",
                        prop: "floorName",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "宿舍名",
                        prop: "roomName",
                        align: 'center'
                    },
                    {
                        label: "床号",
                        prop: "bedNum",
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "更新时间",
                        prop: "updateTime",
                        align: 'center',
                        type: "function",
                        callBack(row) {
                            return row.updateTime ? row.updateTime.slice(0, 16):'-'
                        },
                    },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: "200",
                        slotName: 'operation',
                        className: 'text-spacing-reduction'
                    }
                ],
                check: true,
                height: '',
            },
            // 表格数据
            table_data: [],
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            dialogLoading: false,
            // 分配
            dialogObj: {
                title: '宿舍分配',
                dialogVisible: false,
                width: '900px'
            },
            ruleForm: {
                onlyCode: '',
                schoolId: '',
                dormitoryId: '',
                floorId: '',
                roomType: '',
                roomId: '',
                roomName: '',
            },
            dialogTableList: [],
            dormitoryList: [],
            floorList: [],
            tableListSel: [],
            firstTime: true,
            bedNum:''
        }
    },
    created() {
        this.firstTime = false;
        this.ruleForm.schoolId = this.listQuery.schoolId = this.schoolId
        this.init()
    },
    mounted() {
    },
    activated() {
        if (this.firstTime) {
            this.init();
        }
    },
    deactivated() {
        this.firstTime = true;
    },
    methods: {
        init(){
            this.getList()
            this.getDormitoryList()
            this.getOrgList()
        },
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1
            }
            this.loadingTable = true
            const schoolDormitory = new SchoolDormitory()
            schoolDormitory.getDormitoryDistributionList(this.listQuery).then((res) => {
                if (res.data.code === '200') {
                    this.table_data = res.data.data.list;
                    this.total = res.data.data.totalCount;
                } else {
                    this.$message.error(res.data.msg)
                }
                this.loadingTable = false
            })
        },
        getDormitoryList() {
            const schoolDormitory = new SchoolDormitory()
            schoolDormitory.getSchoolDormitoryListCondition({
                schoolId: this.schoolId
            }).then((res) => {
                if (res.data.code === '200') {
                    this.dormitoryList = res.data.data.map((item) => ({
                        label: item.dormitoryName,
                        value: item.id
                    }))
                    this.formData.data.forEach((item) => {
                        if (item.key === 'dormitoryId') {
                            item.list = this.dormitoryList
                        }
                    })
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        getOrgList() {
            let obj = {
                schoolId: this.schoolId,
                jobScope: ["1", "2", "3"]
            };
            this._fet(
                "/school/schoolOrgan/listAuthByConditionSort",
                obj,
            ).then((res) => {
                if (res.data.code === "200") {
                    const list = res.data.data.list;
                    const listClass = list.filter((it) => it.organType != 1);
                    let listArr = formatTreeData(listClass, "id", "parentOrg");
                    this.formData.data.forEach((item) => {
                        if (item.key === "organIdList") {
                            item.list = listArr
                        }
                    });
                }else{
                    this.$message.error(res.data.msg);
                }
            })
        },
        getFloorList(data) {
            const schoolDormitory = new SchoolDormitory()
            return new Promise((resolve => {
                schoolDormitory.getSchoolDormitoryFloorListCondition({
                    dormitoryId: data,
                    schoolId: this.schoolId
                }).then((res) => {
                    if (res.data.code === '200') {
                        resolve(res.data.data.map((item) => ({
                            label: item.floorName,
                            value: item.id
                        })))
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            }))
        },
        changeSel(data) {
            if (data.key === 'dormitoryId') {
                this.formData.data.forEach((item) => {
                    if (item.key === 'floorId') {
                        item.list = []
                        item.value = ''
                    }
                })
                if (data.value) {
                    this.formData.data.forEach( async (item) => {
                        if (item.key === 'floorId') {
                            item.list = await this.getFloorList(data.value)
                        }
                    })
                }
            }
        },
        clickBtn(data) {
            switch (data.item.fn) {
                case 'primary': // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    })
                    this.getList(1);
                    break;
                case 'reset': // 重置
                    this.formData.data.forEach((item) => {
                        if (item.key === 'organIdList') {
                            this.listQuery[item.key] = []
                            item.value = [];
                        } else if (item.key === 'floorId') {
                            this.listQuery[item.key] = ''
                            item.value = '';
                            item.list = []
                        }  else {
                            this.listQuery[item.key] = ''
                            item.value = '';
                        }
                        this.floorList = []
                    })
                    this.getList(1);
                    break;
            }
        },

        /*
           表格相关操作
           handleTableSelectionChange   表格选中
           handleTableDelete            取消分配
           handleDistribution           分配
         */
        handleTableSelectionChange(data) {
            this.tableListSel = data;
        },
        handleTableDelete(data) {
            const delArr = [
                {
                    id: data.id,
                    studentName: data.studentName,
                    onlyCode: data.onlyCode,
                    schoolId: this.schoolId
                }
            ]
            this.handleDelete(delArr)
        },
        handleDistribution(data) {
            this.dialogObj.dialogVisible = true
            this.ruleForm.onlyCode = data.onlyCode
            this.ruleForm.roomType = data.sex
            // this.ruleForm.roomId = data.roomId
            this.ruleForm.roomName = data.roomName
            this.ruleForm.studentName = data.studentName

            this.getDialogTableList()

            this.dialogObj.title = `宿舍分配-` + data.studentName
        },

        /*
            multiDelete             批量取消分配
            handleImport            导入
            handleExport            导出
            downloadErrorList       下载错误信息
         */
        multiDelete() {
            if (this.tableListSel.length === 0) {
                return this.$message.warning('请选择要取消分配的宿舍')
            }
            const delArr = this.tableListSel.map(i => ({
                id: i.id,
                studentName: i.studentName,
                onlyCode: i.onlyCode,
                schoolId: this.schoolId
            }))
            this.handleDelete(delArr)
        },
        handleDelete(arr) {
            this.$confirm('确定要执行取消分配操作吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const schoolDormitory = new SchoolDormitory()
                schoolDormitory.deleteDormitoryDistribution(arr).then((res) => {
                    if (res.data.code === '200') {
                        this.$message.success('取消分配成功')
                        this.getList()
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {
            })
        },
        handleImport() {
            let form = {
                schoolId: this.schoolId,
                menuId:"10010303"
            }
            const filter = this.formData.data.filter(i => i.value)
            if (filter.length > 0) {
                filter.forEach(item => form[item.key] = item.value)
            } else if (this.tableListSel.length > 0) {
                form.onlyCodeList = this.tableListSel.map(i => i.onlyCode)
            }
            downloadFile({
                url: '/school/schoolDormitoryDistribution/templateExport',
                form: form
            }, () => {
                // this.$message.success('下载模板成功')
            }, () => {

            })
        },
        handleExport() {
            let form = {
                schoolId: this.schoolId
            }
            const filter = this.formData.data.filter(i => i.value)
            if (filter.length > 0) {
                filter.forEach(item => form[item.key] = item.value)
            } else if (this.tableListSel.length > 0) {
                form.onlyCodeList = this.tableListSel.map(i => i.onlyCode)
            }
            downloadFile({url: '/school/schoolDormitoryDistribution/export', form: form}, () => {
                // this.$message.success('导出成功')
            }, () => {

            })
        },
        downloadErrorList(data) {
            this.$confirm(data.msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                downloadFile({
                    url: '/school/schoolDormitoryDistribution/errorExport',
                    method: 'post',
                    fileName: '导入错误信息表.xlsx',
                    form: {paramData: data.info.paramData}
                }, () => {
                }, () => {

                })
            }).catch(() => {
            })
        },
        /*
            分配
            getDialogTableList  获取宿舍列表
            handleSubmit        确定分配
            handleClose         关闭
            dormitoryChange     宿舍楼修改
         */
        getDialogTableList() {
            const schoolDormitory = new SchoolDormitory()
            schoolDormitory.getRoomDistributeList(this.ruleForm).then((res) => {
                if (res.data.code === '200') {
                    this.dialogTableList = res.data.data;
                    console.log(this.dialogTableList,'this.dialogTableList')
                } else {
                    this.$message.error(res.data.msg)
                }
                this.loadingTable = false
            })
        },
        handleSubmit(val) {
            this.dialogLoading = true
            const obj = {
                schoolId: this.schoolId,
                onlyCode: this.ruleForm.onlyCode,
                studentName: this.ruleForm.studentName,
                roomId: this.ruleForm.roomId,
                roomName: this.ruleForm.roomName,
                bedNum: val
            }
            const schoolDormitory = new SchoolDormitory()
            schoolDormitory.saveRoomDistribute(obj).then((res) => {
                if (res.data.code === '200') {
                    this.$message.success('分配成功')
                    this.getList()
                    this.handleClose()
                } else {
                    this.$message.error(res.data.msg)
                }
                this.dialogLoading = false
            })
        },
        handleClose() {
            this.dialogObj.dialogVisible = false
            Object.keys(this.ruleForm).forEach((key) => {
                if (key !== 'schoolId') {
                    this.ruleForm[key] = ''
                }
            })
            this.dialogTableList = []
        },
        async dormitoryChange(data) {
            this.floorList = []
            this.ruleForm.floorId = ''
            if (data) {
                this.floorList = await this.getFloorList(data)
                this.getDialogTableList()
            } else {
                this.dialogTableList = []
            }
        },
        handleAvatarSuccess(res) {
            if (res.code === '200') {
                this.$message.success('导入成功')
                this.$emit('handleSuccess')
            } else {
                this.downloadErrorList(res)
            }
        },
        beforeAvatarUpload(file) {
            const isType = ['.xlsx', '.xls'].includes(file.name.substring(file.name.lastIndexOf('.')))
            const isLt2M = file.size / 1024 / 1024 < 10;
            const is50 = file.name.length < 50
            if (!is50) {
                this.$message.error('上传文件名称不能超过50个字符!');
                return false
            }
            if (!isType) {
                this.$message.error('上传文件只能是 .xls或.xlsx 格式!');
                return false
            }
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 10MB!');
                return false
            }
        },
    }
}
</script>

<style scoped lang="scss">

.upload-demo {
    display: inline-block;
}
.img {
    width: 14px;
    float: left;
    margin-top: 2px;
    padding-right: 6px;
}
.el-button {
    font-size: 14px;
    margin-left: 10px;
}
.download-btn {
    border: 0;
    background-color: #3dc5f2;
    &:hover {
        background-color: #5ecff5;
    }
}
</style>
